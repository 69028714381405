<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="code"
          :label="placeholder"
          class="c-input-small"
          dense
          outlined
          clearable
          hide-details
          @keyup.enter="inputScan"
          append-icon="mdi-qrcode-scan"
          @click:append="showQRCodeScan('code')"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
        v-if="currentStowingItem && currentStowingItem.id"
      >
        <div class="pb-2">
          {{ $t("labels.basket") }} {{ currentStowingItem.basket_index + 1 }}:
          {{ currentStowingItem.sku }} x {{ currentStowingItem.quantity }}
        </div>
        <v-alert color="error" text dark class="text-h3 mb-0">
          {{ currentStowingItem.cell_id }}
        </v-alert>
        <v-alert color="green" text dark class="mb-0">
          <div class="text-h3 mb-2">
            {{ currentStowingItem.sku }} x
            {{ currentStowingItem.quantity }}
          </div>
          <div class="black--text fs-12">
            {{ currentStowingItem.customer_goods_barcode }} -
            {{ currentStowingItem.name }}
          </div>
        </v-alert>
        <v-alert color="secondary" text dark class="text-h4">
          <span class="text-uppercase"> {{ $t("labels.basket") }} </span>:
          {{ currentStowingItem.basket_index + 1 }}
          [{{ currentStowingItem.basket_code }}]
        </v-alert>
        <div class="pt-2" v-if="prevStowingItem && prevStowingItem.id">
          {{ $t("labels.previous_command") }}: {{ $t("labels.basket") }}
          {{ prevStowingItem.basket_index + 1 }}: {{ prevStowingItem.sku }} x
          {{ prevStowingItem.quantity }} - {{ prevStowingItem.cell_id }}
        </div>
        <div>
          <v-btn block color="warning" @click="dialogConfirm = true">{{
            $t("labels.add_position")
          }}</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogConfirm" persistent max-width="350px">
      <ConfirmBox
        v-if="dialogConfirm"
        :title="$t('labels.add_location')"
        :description="
          $t('messages.sure_to_add_location', { sku: currentStowingItem.sku })
        "
        :label="$t('labels.scan_position_to_confirm_add')"
        :placeholder="$t('labels.scan_position_to_confirm_add')"
        :codes="checkingCodes"
        @cancel="cancelConfirm"
        @confirm="addStowingLocation"
      />
    </v-dialog>

    <v-dialog v-model="qrScanDialog" max-width="100vw">
      <QRCodeScanner
        v-if="qrScanDialog"
        :name="qrScanType"
        :auto-close="false"
        @close="hideQRCodeScan"
        @onScanned="onQRScanned"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
const _ = require("lodash");

export default {
  name: "GoodsReturnStowing",
  components: {
    ConfirmBox: () => import("@/components/common/ConfirmBox"),
    QRCodeScanner: () => import("@/components/common/QRCodeScanner"),
  },
  props: {
    activeCommand: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    detailCommand: [],
    isLoading: false,
    code: null,
    items: [],
    currentStowingItem: {},
    currentStowingItemIndex: null,
    step: 1,
    dialogConfirm: false,
    qrScanDialog: false,
    qrScanType: null,
  }),
  computed: {
    prevStowingItem() {
      if (!this.currentStowingItemIndex || this.currentStowingItemIndex < 1) {
        return {};
      }
      return this.items[this.currentStowingItemIndex - 1];
    },
    placeholder() {
      switch (this.step) {
        case 1:
          return this.$t("labels.scan_basket_code");
        case 2:
          return this.$t("labels.qr_sku_sub_barcode");
        case 3:
          return this.$t("labels.scan_position");
        default:
          return "";
      }
    },
    checkingCodes() {
      if (this.items[this.currentStowingItemIndex]) {
        return [this.items[this.currentStowingItemIndex].cell_id];
      } else {
        return [];
      }
    },
  },
  methods: {
    showQRCodeScan(type) {
      this.qrScanDialog = true;
      this.qrScanType = type;
    },
    hideQRCodeScan() {
      this.qrScanDialog = false;
      this.qrScanType = null;
    },
    onQRScanned(filter) {
      this[filter.name] = filter.value;
      this.inputScan();
    },
    async getDetailCommand() {
      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-return/v1/get-detail-command",
          {
            id_command: this.activeCommand.id,
          }
        );
        this.detailCommand = [...data];
        this.isLoading = false;
        // this.locationSort()
        this.sortLocations();
      } catch (e) {
        this.isLoading = false;
      }
    },

    async inputScan() {
      switch (this.step) {
        case 1:
          await this.scanBasketCode();
          break;
        case 2:
          await this.scanSku();
          break;
        case 3:
          await this.scanCell();
          break;
        default:
          return "";
      }
    },

    async scanBasketCode() {
      if (["" + this.currentStowingItem.basket_code].includes(this.code)) {
        this.step = 2;
        this.code = null;
        document.getElementById("success_sound_player").play();
      } else {
        this.code = null;
        document.getElementById("error_sound_player").play();
      }
    },

    async scanSku() {
      try {
        const { data } = await httpClient.post("/goods/v1/get-by-code-or-uid", {
          code: this.code,
          id_goods: this.currentStowingItem.id_goods,
        });
        if (!data) {
          this.$vToastify.error(
            this.$t("messages.wrong_type", { type: this.placeholder })
          );
          this.code = null;
          this.isLoading = false;
          document.getElementById("error_sound_player").play();
          return false;
        }
        this.step = 3;
        this.code = null;
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
      /*if ([''+this.currentStowingItem.sku, this.currentStowingItem.customer_goods_barcode].includes(this.code)) {
        this.step = 3
        this.code = null
        document.getElementById('success_sound_player').play()
      } else {
        this.code = null
        document.getElementById('error_sound_player').play()
      }*/
    },

    async scanCell() {
      if ([this.currentStowingItem.cell_id].includes(this.code)) {
        this.code = null;

        if (this.isLoading) {
          this.isLoading = false;
          this.$vToastify.warning(this.$t("messages.loading"));
          return false;
        }
        this.isLoading = true;

        try {
          const { data } = await httpClient.post(
            "/goods-return/v1/basket-stowing",
            {
              ...this.currentStowingItem,
            }
          );
          this.detailCommand = [...data];
          this.isLoading = false;
          this.step = 1;
          this.sortLocations();
        } catch (e) {
          this.isLoading = false;
        }

        document.getElementById("success_sound_player").play();
      } else {
        this.code = null;
        document.getElementById("error_sound_player").play();
      }
    },

    cancelConfirm() {
      this.dialogConfirm = false;
      this.isLoading = false;
    },

    async addStowingLocation() {
      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods-return/v1/add-stowing-location",
          {
            ...this.currentStowingItem,
          }
        );
        this.detailCommand = [...data];
        this.isLoading = false;
        this.dialogConfirm = false;
        this.currentStowingItemIndex = null;
        this.sortLocations();
      } catch (e) {
        this.isLoading = false;
      }
    },

    getZoneToNumber(zone) {
      return zone ? zone.charCodeAt(0) * 1000 : 0;
    },

    sortLocations() {
      let locations = [...this.detailCommand];

      // 0. Lấy tất cả vị trí có liên kết
      let tempPositions = _.chain(locations)
        .map((l) => {
          const zone = this.getZoneToNumber(l.zone);
          const position = zone + parseInt(l.position);
          const bff = l.bff ? zone + parseInt(l.bff) : position;
          return [position, bff];
          /* if (!l.bff) {
          return [ parseInt(l.position), parseInt(l.position) ]
        }
        return [ parseInt(l.position), parseInt(l.bff) ] */
        })
        .uniq()
        .value();

      let positions = [];
      _.each(tempPositions, (tp) => {
        let pExisted = _.find(positions, (p) => {
          return (
            (p[0] === tp[0] && p[1] === tp[1]) ||
            (p[0] === tp[1] && p[1] === tp[0])
          );
        });
        if (!pExisted) {
          positions.push(tp);
        }
      });

      // 1. Chuyển tất cả vị trí có liên kết dãy về 1 dãy ảo
      let virtualPositions = [];
      _.each(locations, (l) => {
        let bffPosition = _.find(positions, (p) => {
          const zone = this.getZoneToNumber(l.zone);
          const position = zone + parseInt(l.position);
          return position === p[0] || position === p[1];
        });
        l.virtualPosition = Math.min(bffPosition[0], bffPosition[1]);
        if (virtualPositions.indexOf(l.virtualPosition) < 0) {
          virtualPositions.push(l.virtualPosition);
        }
      });

      // 2. Nhóm theo 1 dãy mới
      let locationGroups = _.groupBy(locations, "virtualPosition");
      virtualPositions.sort((a, b) => {
        return a - b;
      });

      // 3. Sort từng dãy
      let orderAsc = true;
      let result = [];
      for (let vp of virtualPositions) {
        let vpLocations = locationGroups[`${vp}`];
        let newVpLocations = _.orderBy(
          vpLocations,
          ["indexing", "quantity"],
          [orderAsc ? "asc" : "desc", "asc"]
        );
        _.each(newVpLocations, (nvl) => {
          result.push(nvl);
        });
        orderAsc = !orderAsc;
      }

      this.items = [...result];
      this.nextStowingItem();
    },

    nextStowingItem() {
      if (this.currentStowingItemIndex == null) {
        this.currentStowingItemIndex = 0;
      } else {
        this.currentStowingItemIndex =
          this.currentStowingItemIndex == this.items.length - 1
            ? null
            : this.currentStowingItemIndex + 1;
      }

      if (this.currentStowingItemIndex != null) {
        if (this.items[this.currentStowingItemIndex].status) {
          this.nextStowingItem();
        } else {
          this.currentStowingItem = this.items[this.currentStowingItemIndex];
        }
      } else {
        this.commandDone();
      }
    },

    commandDone() {
      this.$emit("done", true);
    },
  },
  mounted() {
    this.getDetailCommand();
  },
};
</script>

<style scoped></style>
